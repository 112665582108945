.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.not-found h1 {
    font-size: 10rem;
    margin: 0;
    color: #ff5722;
}

.not-found h2 {
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.not-found p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.not-found a {
    color: #fff;
    background-color: #ff5722;
    padding: 0.75rem 2rem;
    border-radius: 0.25rem;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.not-found a:hover {
    background-color: #ff6d3f;
}
