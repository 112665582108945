.Container {
    display: grid;
    grid-template-columns: 35% 1fr;
    background-color: white;
    align-items: center;
    justify-items: center;
    margin: 2rem;
    height: 100%;
    min-height: 80vh;
}
.Container h3 {
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-align: center;
    color: gray;
    font-weight: 550;
    font-family: "Open Sans", sans-serif;
}
.Container img {
    width: 100%;
}
.modal {
    /* // display: flex; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: auto;
    border-radius: 10px;
    animation: bounce 0.5s ease;
    animation-fill-mode: forwards;
    outline: none;
    opacity: 0;
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
}
.wrapper {
    display: grid;
    gap: 2rem;
    align-content: baseline;
    justify-items: center;
    align-items: center;
    padding: 1rem;
    padding-top: 2rem;
    font-family: "Poppins", sans-serif;
}
.wrapper img {
    width: 15rem;
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

/* Media queries for responsive adjustments */
@media (max-width: 768px) {
    .modal {
        width: 90%;
    }
}
