.Container {
  padding: 1rem 2rem;
  display: grid;
  gap: 2rem;
  align-content: baseline;
  min-height: 87.3vh;
  height: 100%;
  background-color: #ebf2fa;
}
.main_cont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Btns {
  width: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.control-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border: 1px solid #ccc;

  background-color: white;
  color: #0077b6;
  gap: 0.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1rem;
}
.button:hover {
  background-color: #ececec;
}

.wrapper {
  padding: 15px 15px 40px;
  background-color: #fff;
  border-radius: 4px;
  display: grid;
  gap: 0.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 99%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.wrapper h3 {
  border-bottom: 1px solid #ddd;
  padding: 0 0 5px;
  margin: 0 0 15px;
  font-size: 1.3rem;
  display: block;
  font-weight: 500;
  line-height: 1.2;
  font-family: inherit;
  color: #212529;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: left;
}

.active {
  border-color: #dee2e6 #dee2e6 #f9f9f9 !important;
  background-color: #f9f9f9 !important;
}

.serchFileter {
  display: grid;
  width: 50%;
  grid-template-columns: 24% 75%;
  padding: 0.6rem 0.4rem;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.serchFileter select {
  background-color: #ffff;
  color: #2a2d2d;
  padding: 0.3rem;
  border-style: none;
  border-right: 2px solid #ccc;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
}
.serchFileter input {
  background-color: #ffff;
  color: #2a2d2d;
  padding: 0.5rem;
  border-style: none;
  outline: none;
  text-indent: 10px;
  font-size: 1rem;
}

.Table {
  display: grid;
  gap: 1rem;
  width: 100%;
  overflow: auto;
}
.Table table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  min-width: 700px;
  text-align: left;
  border: 1px solid #ccc;
}
.Table table thead {
  color: white;
}
.Table table thead th {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #56638f;
  padding: 1rem 0rem;
  /* letter-spacing: 0.05rem; */
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  width: 180px;
}

.Table table tbody tr {
  text-align: center;
  cursor: pointer;
  color: #777;
}

.Table table tbody tr td {
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  padding: 0.7rem 0.5rem;
  color: inherit;
  cursor: pointer;
  text-transform: capitalize;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  position: relative;
  /* display: -webkit-box;
  -webkit-box-orient: vertical; */
}

.Table table tbody tr td img {
  width: 10rem;
  /* height: 50px; */
  /* border-radius: 50%; */
  object-fit: fill;
}
.Action-btns {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.Block-btns {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.block {
  border: 1px solid #f05252;
  background-color: #f05252;
  font-size: 0.9rem;
  color: #ffff;
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 5px;
}
.unblock {
  border: 1px solid #00cc99;
  background-color: #00cc99;
  font-size: 0.9rem;
  color: #ffff;
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 5px;
}

.Action-btns button {
  border: none;
  background-color: inherit;
  font-size: 1.4em;
  color: #0077b6;
  cursor: pointer;
}
.Status-active span {
  background-color: #def7ec;
  color: #0e9f6e;
  padding: 0.5rem;
  border-radius: 10px;
}
.Status-inactive span {
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #fde8e8;
  color: #f05252;
}

.status-male {
  background-color: #5bc0de;
  color: white;
  border-radius: 36px;
  padding: 5px;
}

.status-pre {
  background-color: #00cc99;
  color: white;
  border-radius: 36px;
  padding: 5px;
}

.status-No {
  background-color: #fe6f5e;
  color: white;
  border-radius: 36px;
  padding: 5px;
}
.comments {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.status-female {
  background-color: #337ab7;
  color: white;
  border-radius: 36px;
  padding: 5px;
}

.status-others {
  background-color: #b85c5c;
  color: white;
  border-radius: 36px;
  padding: 5px;
}

.Btns {
  display: flex;
  border-bottom: 1px solid #56638f;
}

/* select All styles */
.combinedWrapper {
  display: grid;
  grid-template-columns: 80% 20%;
  gap: 0.5rem;
}

.selectAll {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.selectAll > input {
  width: 30px;
  height: 20px;
  outline: none;
  border: 1px solid #56638f;
}

.selectAll > button {
  color: #ffffff;
  padding: 0.6rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  background-color: #56638f;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.selectAll > button:disabled {
  background-color: #dddd;
  color: #7777;
}

.wrapper form {
  padding: 0 0.5rem;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  max-width: 100%;
  gap: 0.5rem 2rem;
  align-items: center;
  /* justify-content: center; */
}

.Formwrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Formwrapper .forminputs {
  padding: 0 0.5rem;
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  gap: 0.5rem 2rem;
  align-items: center;
  /* justify-content: center; */
}

.form-control {
  display: grid;
  gap: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
    helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
    segoe ui emoji, segoe ui symbol, noto color emoji;
}

.form-control label {
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.form-control input,
.form-control select,
.form-control textarea {
  display: block;
  /* width: 93%; */
  height: 1.6rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}
.form-control input:focus,
.form-control select:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);

  border: 1px solid rgba(81, 203, 238, 1);
}
.form-control span {
  font-family: inherit;
  color: red;
}
.form-control input:disabled {
  background-color: lightgray;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control select {
  height: 2.4rem;
  width: 100%;
}
/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.wrapper form > button {
  border: 1px solid #0077b6;
  color: #212529;
  background-color: #fff;
  display: inline-block;
  font-weight: 400;
  color: #0077b6;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: fit-content;
  height: fit-content;
  grid-column: 1/-1;
  justify-self: center;
}

.Formwrapper .forminputs > button {
  border: 1px solid #0077b6;
  color: #212529;
  background-color: #fff;
  font-weight: 400;
  color: #0077b6;
  text-align: center;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: fit-content;
  height: fit-content;
}

.wrapper form button:disabled {
  background-color: white;
  border: none;
  width: 6rem;
  display: grid;
  align-items: center;
  justify-items: center;
}

.file-upload-wrapper {
  border: 1px solid #ccc;

  grid-column: 1/-1;
}
.file-upload-wrapper header {
  padding: 0.3rem;
  border-bottom: 1px solid #ccc;
}
.upload-image {
  padding: 1rem 0;
  width: 100%;
  height: 9rem;
  display: grid;
  align-items: center;
  cursor: pointer;
}
.file-images-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  grid-column: 1/-1;
}
.file-images-wrapper img {
  /* width: 100px; */
  height: 80px;
}
.image {
  position: relative;
}

/* Charts  styles */
.maincontent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.maincontent h1 {
  background-color: #5bc0de;
  color: #ffff;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0px;
  padding: 1rem;
  border-radius: 10px;
}
.userchart {
  padding: 0px;
  object-fit: fill;
  overflow: scroll;
  scrollbar-color: #ffff;
  padding-bottom: 5px;
}

.cartsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  align-items: baseline;
}

.UserCahrts {
  margin: 1rem 0;
}

.emptydata {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
}

.emptydata img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  overflow: hidden;
}

.Chart {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.Chart h2 {
  max-width: 100%;
  font-size: 1.2rem;
  background-color: #00cc99;
  color: #ffff;
  font-weight: 500;
  padding: 0.5rem 1rem;
  margin: 0px;
  border-radius: 10px;
}

/* Cards  styles */
.card-container {
  display: grid;
  align-items: center;
  gap: 1rem;
}

#headh2 {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0px;
  background-color: transparent !important;
  color: #5bc0de !important;
}
.cards-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
}

.card {
  max-width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 40% 60%;
  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.card-logo {
  background-color: #154360;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-logo p {
  color: #ffffff;
  font-size: 1.2rem;
}
.card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-body h5 {
  font-size: 1.4rem;
  color: #154360;
}

@media screen and (max-width: 1200px) {
  .cartsContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    align-items: baseline;
  }
}

@media screen and (max-width: 1020px) {
  .cards-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .Container {
    padding: 1rem;
  }
  .cards-menu {
    display: grid;
    grid-template-columns: 1fr;
  }
}
