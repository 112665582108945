.TopNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  z-index: 10;
  /* position: fixed; */
  top: 0;

  right: 0;
  /* width: 100%; */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                               supported by Chrome, Edge, Opera and Firefox */
}
.profile_section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #ffff;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 5px;
  width: 300px;
}

.search-input {
  flex: 1;
  background: none;
  border: none;
  color: #7a7979;
  padding: 1rem;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
}

.search-input::placeholder {
  color: #7a7979;
  font-size: 1rem;
  font-weight: 600;
}

.s-button {
  background-color: #2697ff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.s-button svg {
  color: white;
}
.user {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  background-color: #ffff;
  color: #7a7979;
  padding: 10px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
}
.user p {
  margin: 0 !important;
  padding: 0 !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.user img {
  width: 2.3rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                               supported by Chrome, Edge, Opera and Firefox */
}
.user button {
  cursor: pointer;
  background-color: inherit;
  /* border: none; */
  font-size: 1.3rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  place-content: center;
}

.loading {
  background-color: white;
  display: grid;
  gap: 0.2rem;
  align-items: center;
  justify-items: center;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: transparent;
  border-style: none;
  outline: none;
  color: #7a7979;
  cursor: pointer;
}

@keyframes animation-dropdown-menu-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animation-dropdown-menu-move-up {
  0% {
    margin-top: 10px;
  }
  100% {
    margin: 0;
  }
}
