.Container {
  background-color: #5bc0de;
  min-height: 92vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  position: fixed;
  width: 250px;
  left: 0;
  z-index: 100;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  overflow: scroll;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding-top: 0.5rem;
  user-select: none;
  transition: width 0.2s ease;
}
.Container a {
  user-select: none;
}
.Container::-webkit-scrollbar {
  display: none;
}
.seo {
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
}
.seo {
  display: grid;
  gap: 0.5rem;
  /* overflow: scroll; */
}
.seo-item {
  display: flex;
  font-size: 0.9rem;
  cursor: pointer;
  align-items: center;
  gap: 1rem;
  color: #324889;
  padding: 0 0.8rem;
}

.seo-item-nav {
  display: grid;
  gap: 0.3rem;
  overflow: hidden;
}

.seo-item p {
  color: #324889;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  font-weight: 500;
  font-size: 1.05rem;
  font-family: "Open Sans", sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                 white-space: nowrap; /* Prevent the paragraph from wrapping to the next line */
}
.cancel {
  background-color: red;
  color: white;
}
.hide {
  width: 70px;
}

.hamburger {
  width: 100%;
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  min-height: 150px;
  /* border-bottom: 1px solid #707275; */
  justify-items: center;
  align-items: center;
  margin: 1rem 0 0 0;
  /* padding: 0.5rem; */
}
.hamburger > img {
  cursor: pointer;
  width: 100%;
  max-width: 10rem;
  align-self: center;
  transition: opacity 0.2s ease;
  flex-grow: 2;
  justify-self: center;
  text-align: center;
}
.hide .hamburger {
  grid-template-columns: 1fr;
}
.hide .hamburger img {
  display: none;
}
.hamburger-icon {
  cursor: pointer;
  border: none;
  padding: 0 !important;
  outline: none;
  color: #9f9ea1;
}
.hide .hamburger-icon {
  right: 10px;
}
.navlinks {
  display: grid;
  gap: 0.3rem;
  margin-top: 2rem;
}
.nav-icon {
  font-size: 1.4rem;
  color: inherit;
}
.navlinks a,
.Container button {
  align-items: center;
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #324889;
  text-wrap: nowrap;
  overflow: hidden;
  gap: 1rem;
  text-decoration: none;
  user-select: none;
  outline: none;
}
.navlinks a {
  padding: 0.5rem 0.8rem;
}
.navlinks a:hover {
  color: #ffff;
  background-color: #56638f;
}
.seo-item-nav a {
  padding: 0.5rem;
}
.navlinks a span {
  /* margin-top: 0.4rem; */
  overflow: hidden;
}

.sub-link-hide {
  padding-left: 0 !important;
  border: none !important;
}

.navlinks a img,
.Container button img {
  width: 1.1rem;
}
.hide .navlinks a {
  display: flex;
  place-content: center;
}
.navActive {
  color: #ffff !important;
  background-color: #56638f;
  transition: background-color 0.2s ease;
}
.navInactive {
  color: #324889;
}
.Container button {
  border: none;
  background-color: inherit;
  color: white !important;
  cursor: pointer;
  padding: 0.7rem 1rem;
}
.Container button img {
  padding: 2rem 0 0 0;
}
/* .logout {
    margin-top: 1rem;
} */
.hidden {
  opacity: 0;
}
.hide .logout {
  /* padding: 1rem; */
  border-top: 3px solid white;
}

.hide button image {
  padding: 0;
}
/* .hide .Container button p {
    padding: 0;
} */
.img-size {
  width: 3rem !important;
}
.hide .navlinks {
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}
.hide .navlinks a,
.hide .seo-item {
  background-color: transparent;
  transition: background-size 0.2s;
  background-image: linear-gradient(to right, white, white);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}
.hide .seo-item {
  padding: 0.8rem 0;
  display: flex;
  place-content: center;
  user-select: none;
  outline: none;
  border: none;
}
.hide .navlinks a:hover,
.hide .seo-item:hover {
  color: #0077b6;
  background-size: 100% 100%;
}
.seo-item-nav a {
  padding-left: 3rem;
}

@media screen and (max-width: 900px) {
  .Container {
    overflow: scroll;
  }
  .backdrop {
    user-select: none;
    position: fixed;
    overflow: hidden;
    width: 100vw;
    min-height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);

    z-index: 10;
  }
  .Container {
    gap: 1rem;
    /* width: 70px; */
  }
  /* .seo-item p {
        display: none;
    } */
}
