.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  animation: linear normal forwards;
  animation-name: run;
  animation-duration: 100ms;
  display: flex;
  justify-content: flex-end;
  z-index: 100 !important;
  transition: 100ms;
}
.modal-content {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  opacity: 1;
  overflow: scroll;
  min-width: 600px;
  width: fit-content;
  background: #ffffff;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  position: relative;
}
.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-content-header {
  padding: 1rem 1.5rem;
  font-weight: 500;
  background-color: #f9fafb;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
}
.customer-info {
  padding: 1rem 1rem;
  display: grid;
  gap: 1rem;
}

.close-btn {
  align-self: center;
  background-color: white;
  border: 1px solid white;
  outline: none;
  color: red !important;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.form_wrapper {
  display: grid;
  width: 100%;
  padding: 2rem 0;
}

.form_wrapper form {
  display: grid;
  gap: 1rem;
  width: 80%;
  margin: 0 auto;
}

.form-control {
  display: grid;
  gap: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
    helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
    segoe ui emoji, segoe ui symbol, noto color emoji;
}

.form-control label {
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.form-control input {
  position: relative;
  display: block;
  width: 95%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  height: 1.6rem;
  color: #474646;
  text-indent: 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-control select {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  height: 2.5rem;
  color: #474646;
  text-indent: 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.form-control input:focus,
.form-control select:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);

  border: 1px solid rgba(81, 203, 238, 1);
}

.btn-wrapper {
  display: flex;
  flex-flow: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.btn-wrapper button {
  border: 1px solid #0077b6;
  color: #212529;
  background-color: #fff;
  font-weight: 400;
  color: #0077b6;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.remove {
  border: 1px solid #b62400 !important;
  color: #212529;
  background-color: #ffffff;
  font-weight: 400;
  color: #b62400 !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control span {
  font-family: inherit;
  color: red;
}

.btn-wrapper button::-webkit-outer-spin-button,
.btn-wrapper button::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
