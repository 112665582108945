.Wrapper {
  display: flex;
  /* grid-template-columns: 230px 1fr; */
}
.main-content {
  /* display: flex; */
  flex-direction: column;
  height: 100%;
  gap: 2rem;
  background: white;
  min-height: 100vh;
  z-index: unset;
  height: 100%;
  width: 100%;
  margin-left: 250px;
  transition: margin-left 0.3s ease;
}
.nav-hide {
  margin-left: 70px !important;
}
@media screen and (max-width: 900px) {
  .Wrapper {
    grid-template-columns: 70px 1fr !important;
  }
  .main-content {
    z-index: 1;
    margin-left: 70px;
  }
  /* .nav-hide {
        margin-left: 0 !important;
    } */
}
