* {
  margin: 0;
  padding: 0;
}
.dropdown-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.rc-time-picker-input:-webkit-autofill,
.rc-time-picker-input:-webkit-autofill:focus,
.rc-time-picker-input:-webkit-autofill:hover {
  display: none !important;
  visibility: hidden !important;
  color: red !important;
}
.dropdown-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 100ms, transform 100ms;
}

.dropdown-exit {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 100ms, transform 100ms;
}
.event-tooltip {
  position: absolute;

  z-index: 9999;
  display: block;

  max-width: 300px; /* Adjust the tooltip width as needed */
}
.border-red-600 {
  border: 1px solid red !important;
}
.important {
  color: red;
}
@media screen and (max-width: 1000px) {
  .event-tooltip {
    display: none;
    /* color: rgba(4, 135, 54, 0.96);
        color: rgba(184, 26, 73, 0.96);
        color: rgba(123, 145, 11, 0.718); */
  }
}
.swal-title {
  margin: 0px;
  font-size: 18px;
}
.swal-text {
  background-color: #fefae3;
  padding: 17px;
  border: 1px solid #f0e1a1;
  display: block;
  margin: 10px 22px;
  text-align: center;
  color: #61534e;
}
.swal-icon {
  margin: 5px auto;
}
/* Customize pagination styles */
/* .pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.pagination li {
    margin-right: 10px;
}

.pagination li a {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid #ccc;
    color: black;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
}

.pagination li a:hover {
    background-color: #a9a3a3;
    color: #fff;
}

.pagination .active a {
    background-color: #a9a3a3;
    color: #fff;
    font-weight: bold;
}
.pagination .disabled a {
    background-color: lightgray;
    color: white;
    border-color: transparent;
}
.pagination .disabled a:hover {
    background-color: lightgray;
}

.pagination .previous a,
.pagination .next a {
    background-color: #1e87db;
    border: 1px solid #1e87db;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
} */

.pagination-container {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

li {
  padding: 0.6rem 0.2rem;
}

.pagination {
  display: flex;
  justify-content: center;
}

.page-item {
  /* margin: 0 5px; */
  cursor: pointer;
}

.page-link {
  padding: 0.5rem 1rem;
  border: 1px solid #5bc0de;
  color: #2a2d2d;
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
}

.page-item.previous .page-link,
.page-item.next .page-link {
  background-color: #5bc0de;
  color: #fff;
}

.page-link:hover {
  background-color: #5bc0de;
  color: #ffff;
}

.active .page-link {
  background-color: #5bc0de;
  color: #ffff;
  border-color: #5bc0de;
  border-radius: 10px;
}

/* Pagination separator style (e.g., "..." between pages) */
.pagination .break a {
  border: none;
  color: #1e87db;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ImageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.image_flow {
  display: grid;
  grid-template-columns: 1fr;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.image_co {
  padding: 1rem;
  width: 10rem;
  height: 10rem;
}
.user_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.nodata_text {
  text-align: center;
  color: #2a2d2d;
  margin: auto;
}
